// 
// Page-title
// 

.page-title-box {
    

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: 16px !important;
    }

    .page-title {
        line-height: 70px;
    }
}